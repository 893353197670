import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const servicesData = [
  {
    id: 1,
    icon: 'fas fa-clone',
    title: 'Análises Clínicas',
    description: 'Preço: 75.00 USD',
    descriptionUN: 'Preço Anual: 750 USD'
  },
  {
    id: 2,
    icon: 'fas fa-snowflake',
    title: 'Enfermagem',
   description: 'Preço: 75.00 USD',
    descriptionUN: 'Preço Anual: 750 USD'
  },
  {
    id: 3,
    icon: 'fas fa-plug',
    title: 'Gestão de Empresas',
  description: 'Preço: 60.00 USD',
    descriptionUN: 'Preço Anual: 600 USD'
  },
  {
    id: 5,
    icon: 'fas fa-trophy',
    title: 'Gestão de Recursos Humanos',
    description: 'Preço: 60.00 USD',
    descriptionUN: 'Preço Anual: 600 USD'
  },
  {
    id: 6,
    icon: 'fas fa-life-ring',
    title: 'Engenharia Informática',
    description: 'Preço: 75.00 USD',
    descriptionUN: 'Preço Anual: 750 USD'
  },
  {
    id: 7,
    icon: 'fas fa-life-ring',
    title: 'Língua Portuguesa Para Estrangeiros',
    description: 'Preço: 100.00 USD',
    descriptionUN: 'Preço Anual: 1000 USD'
  },
  {
    id: 8,
    icon: 'fas fa-life-ring',
    title: 'Lingua Inglesa Para Nacionais',
    description: 'Preço: 70.00 USD',
    descriptionUN: 'Preço Anual: 700 USD'
  },
  {
    id: 9,
    icon: 'fas fa-life-ring',
    title: 'Contabilidade',
    description: 'Preço: 60.00 USD',
    descriptionUN: 'Preço Anual: 600 USD'
  },
]

function Appprice() {
  return (
    <section id="services" className="block services-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Nossos Cursos</h2>
          <div className="subtitle">Cursos que Lecionamos</div>
        </div>
        <Row>
          {
            servicesData.map(services => {
              return (
                <Col sm={4} className='holder' style={{border:'1px solid #00761F', borderRadius:'12px', marginTop:'12px'}} key={services.id}>
                  <div className="icon">
                    <i className={services.icon}></i>
                  </div>
                  <h3 style={{color:'#00761F'}}>{services.title}</h3>
                  <p><b>{services.description}</b></p>
                  <p><b>{services.descriptionUN}</b></p>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
  );
}

export default Appprice;