import React from 'react'

function AppBanner() {
  return (
    <section>
        <div id='backImageInt' className="banner position-relative">
        <div className='title-banner' id='instituition-Banner'>
        <h1><b>Estudantes Internacionais</b></h1>
        <h5 className='text-gray-500'>Sejam Todos(as) Bem-vindos ao Instituto Superior Politécnico Atlântida</h5>
        <h6 style={{color:'#FFFFFF'}}>"Onde a diversidade cultural e o conhecimento se encontram para criar uma experiência acadêmica única e enriquecedora"<br /></h6>
        </div>
        </div>
    </section>
  )
}

export default AppBanner