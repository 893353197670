import React from 'react';
import AppHeader from '../../../components/Header';
import AppLayout from '../../../components/layout';
import AppBanner from '../../../components/Internacional/Banner';
import AppcursosInternational from '../../../components/internacionalCurs'


function cursos() {
    return (
        <>
        <header id='header'>
        <AppHeader />
        </header>
        <AppBanner />
            <main className="px-3 px-md-5 px-xl-5 px-xxl-5 py-2">
            <br />
            <div className="row flex-row-reverse">
                <div className="col-12 col-md-8 col-lg-9 col-xl-10 col-xxl-10">
                <br />
                <div className="details-magazine" id='detalhes'>
                    <p className="f-reg">
                    <b className="title-apresentation display-6 mb-3 text-success">Cursos de Licenciatura a sua Disposição</b><br />
                    <br />
                    A actual estrutura estabelece a constituição de quatro áreas do conhecimento, a saber: Ciências da Saúde, 
                    Ciências das Engenharias e Tecnologia, Ciências da Gestão e Ciência Sociais. Ao todo, são ministrados 15 cursos de 
                    graduação: licenciatura em Direito, Relações Internacionais, Pedagogia, Psicologia, Sociologia, Gestão de Empresa,
                    Gestão de Recursos Humanos, Contabilidade, Economia, Enfermagem, Análises Clínicas, Engenharia Informática, Engenharia Civil, 
                    Farmácia e Fisioterapia.
                    <br /><br />
                    </p>
                    <br />
                </div>
                <AppcursosInternational />
                <br /><br />
                </div>
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 menu-color">
                <a href="/estudante-internacional" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark  mt-2 f-reg d-flex justify-content-between">
                    <span className="f-reg ">
                    Estudante internacional
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/estudante-internacional" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
                    <span className="f-reg ">
                    Licenciatura
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="# " style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark f-reg d-flex w-100 justify-content-between">
                    <span className="f-reg">
                    Mestrado
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>       
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            </main>
        <AppLayout />
        </>
       
    );
}

export default cursos;