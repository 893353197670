import React from 'react';
import AppHeader from '../../../components/Header';
import AppLayout from '../../../components/layout';
import AppBannerPage from '../../../components/bannerPage';

function Alojamento() {
    return (
         <div>
        <header id='header'>
        <AppHeader />
      </header>
      <AppBannerPage />
        <main className="px-3 px-md-5 px-xl-5 px-xxl-5 py-2">
            <br />
            <div className="row flex-row-reverse">
            <div className="col-12 col-md-8 col-lg-9 col-xl-10 col-xxl-10">
            <br />
            <div className="details-magazine">
                <p className="f-reg f-20">
                    <b style={{textTransform: 'capitalize'}} className="title-apresentation display-6 mb-3 text-success f-24">Programa para o Campus</b><br />
                    <br />
                    O Instituto Superior Politécnica Atlântida (ISP-Atlântida) oferece uma experiência confortável e segura para estudantes que buscam praticidade e proximidade com o campus. Localizado em áreas estratégicas, nossas residências estudantis possuem infraestrutura moderna e acessível, proporcionando um ambiente ideal para o convívio acadêmico e pessoal.
                    <br />
                </p>
                <br />
                <br />
                <p className="f-reg f-20">
                    <b style={{textTransform: 'capitalize'}} className="title-apresentation display-6 mb-3 text-success f-24">Alojamento para o curso de Línguas</b><br />
                    <br />
                    <b className='text-success'>CASA DE FAMILIA</b><br />
                    <b className='text-gray-500'>Preçario:</b> 500 USD <br />
                    <b className='text-success'>Benefícios:</b> Habitação, Alimentação, Água, Luz, Segurança, Internet ,Transporte.
                     <br /> 
                     <br /> 
                     <b className='text-success'> Habitação sobre tutela da escola</b><br />
                    <b className='text-gray-500'>Preçario:</b> 300 USD <br />
                    <b className='text-success'>Benefícios:</b> Habitação, Água ,Luz, Internet, Segurança, Ttransporte.
                    <br />
                    <b style={{color:'red'}}>Observação: </b>Alimentação por conta do estudante<br />
                </p>
                <br />
                <p className="f-reg f-20">
                    <b style={{textTransform: 'capitalize'}} className="title-apresentation display-6 mb-3 text-success f-24">Alojamento para a Licenciatura</b><br />
                    <br />
                    <b className='text-success'>Habitação sobre tutela da escola</b><br />
                    <b className='text-gray-500'>Preçario:</b> 300 USD <br />
                    <b className='text-success'>Benefícios:</b> Habitação, Alimentação, Água, Luz, Segurança, Internet ,Transporte. <br />
                    <b style={{color:'red'}}>Observação: </b>Alimentação por conta do estudante<br />
                     <br /> 
                    <br />
                </p>
                </div>
                <br /><br />
            </div>
            <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2">
                <a href="/internacional" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
                <span className="f-reg ">
                Porque Ispatlantida?
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/estudante-internacional" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
                <span className="f-reg ">
                Estudante Internacional
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/preços" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark f-reg d-flex w-100 justify-content-between">
                <span className="f-reg">
                Preçario
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/alojamento" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-sucess f-reg d-flex w-100 justify-content-between">
                <span className="f-reg">
                Alojamento
                </span>
                <i className="bi text-success bi-arrow-right" />
                </a>
            
            </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
        </main>
        <AppLayout />
        </div>
    );
}

export default Alojamento;