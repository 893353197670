import React from 'react';
import AppHeader from '../../../components/Header';
import AppLayout from '../../../components/layout';
import AppBannerPage from '../../../components/bannerPage';
import AppPricePage from '../../../components/Internacional/price'



function AppPrice() {
    return (
        <>
        <header id='header'>
        <AppHeader />
        </header>
        <AppBannerPage />
            <main className="px-3 px-md-5 px-xl-5 px-xxl-5 py-2">
            <br />
            <div className="row flex-row-reverse">
                <div className="col-12 col-md-8 col-lg-9 col-xl-10 col-xxl-10">
                <br />
                <div className="details-magazine" id='detalhes'>
                    <p className="f-reg">
                    <b className="title-apresentation display-6 mb-3 text-success">Preçarios dos Cursos</b><br />
                    <br />
                    Invista no seu futuro com nossos cursos exclusivos! Oferecemos formações de alta qualidade, pensadas para atender às suas necessidades e objetivos. Com preços acessíveis e opções flexíveis, você pode dar o próximo passo na sua carreira sem comprometer seu orçamento. Escolha o curso que transforma sua vida e comece agora a trilhar o caminho do sucesso! 
                    <br /><br />
                    E o melhor: nossas condições de pagamento facilitadas garantem que você tenha o suporte necessário para crescer sem preocupações. Não deixe para amanhã o que pode ser o primeiro passo para o seu futuro brilhante!"
                    </p>
                    <br />
                </div>

                <AppPricePage />
                <br /><br />
                </div>
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 menu-color">
                <a href="/internacional" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
                    <span className="f-reg ">
                    Porque o Ispatlantida?
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/estudante-internacional" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
                    <span className="f-reg ">
                    Estudante Internacional
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/cursos" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark f-reg d-flex w-100 justify-content-between">
                    <span className="f-reg">
                    Cursos  
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/preços" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-success f-reg d-flex w-100 justify-content-between">
                    <span className="f-reg">
                    Preçario
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/alojamento" style={{textDecoration: 'none'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
                    <span className="f-reg ">
                    Alojamento
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>          
                </div>
            </div>
            <br />
            <br />
            <br />
            </main>
        <AppLayout />
        </>
       
    );
}

export default AppPrice;