import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const blogData = [
  {
    id: 1,
    image: require('../assets/Internacional/cur_04.jpg'),
    title: 'Análises Clínicas',
    description: 'Formação prática e teórica em áreas chave, com foco em tecnologias avançadas para atuação global, clica no botão para saber mais!',
    link: '/preços'
  },
  {
    id: 2,
    image: require('../assets/Internacional/cur_03.jpg'),
    title: 'Enfermagem',
    description: 'No nosso curso de Análises Clínicas, os estudantes internacionais aprendem técnicas avançadas e protocolos globais.',
    link: '/preços'
  },
  {
    id: 3,
    image: require('../assets/Internacional/cur_07.jpg'),
    title: 'Gestão de Empresas',
    description: 'Este curso de Gestão de Empresas, vai capacita-lo de competências essenciais para liderar com sucesso em mercados.',
    link: '/preços'
  },
  {
    id: 4,
    image: require('../assets/Internacional/cur_02.jpg'),
    title: 'Gestão de Recursos Humanos',
    description: 'Você vai adquirir habilidades essenciais para liderar equipes, otimizar processos e implementar estratégias eficazes de gestão.',
    link: '/preços'
  },
  {
    id: 5,
    image: require('../assets/Internacional/cur_05.jpg'),
    title: 'Engenharia Informática',
    description: 'No nosso curso de Engenharia Informática, você aprende tecnologias avançadas e soluções digitais,com foco em inteligência artificial, segurança e programação. ',
    link: '/preços'
  },
  {
    id: 6,
    image: require('../assets/Internacional/cur_08.jpg'),
    title: 'Língua Portuguesa',
    description: 'Aprenda português de forma rápida e prática com nosso curso online, adaptado a todos os níveis. Aulas interativas e suporte dedicado para você alcançar fluência. Adquira já, não perca mais tempo! ',
    link: '/preços'
  },
  {
    id: 7,
    image: require('../assets/Internacional/cur_06.jpg'),
    title: 'Lingua Inglesa',
    description: 'Vem Aprender inglês de forma prática e eficiente com nossos professores qualificados. Aulas dinâmicas e material atualizado para todos os níveis, oferecemos uma abordagem dinâmica e personalizada para todos ',
    link: '/preços'
  }
  ,
  {
    id: 8,
    image: require('../assets/Internacional/cur_01.jpg'),
    title: 'Contabilidade',
    description: 'No curso de Licenciatura em Contabilidade, você adquire conhecimentos em normas internacionais, gestão financeira e auditoria, preparando-se para dominar ferramentas essenciais para uma carreira de sucesso',
    link: '/preços'
  }
]

function AppcursosInternational() {
  const btn_viewsPages={
    marginLeft: 40
  }
  return (
    <section id="blog" className="block blog-block">
      <Container fluid>
        <div className="title-holder">
        </div>
        <Row>
          {
            blogData.map(blog => {
              return (
                <Col sm={3} key={blog.id}>
                  <div className='holder'>
                    <Card>
                      <Card.Img variant="top" src={blog.image} />
                      <Card.Body>
                        <Card.Title>{blog.title}</Card.Title>
                        <Card.Text>
                          {blog.description}
                        </Card.Text>
                        <a style={btn_viewsPages} href={blog.link} className="btn btn-primary">Leia mais<i className="fas fa-chevron-right"></i></a>
                      </Card.Body>
                    </Card>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </Container>
    </section>
  )
}

export default AppcursosInternational;