import React from 'react';
import AppHeader from '../../../components/Header';
import AppLayout from '../../../components/layout';
import AppBanner from '../../../components/Internacional/Banner';


function estudante() {
    return (
        <>
        <header id='header'>
        <AppHeader />
        </header>
        <AppBanner />
            <main className="px-3 px-md-5 px-xl-5 px-xxl-5 py-2">
            <br />
            <div className="row flex-row-reverse">
                <div className="col-12 col-md-8 col-lg-9 col-xl-10 col-xxl-10">
                <br />
                <div className="details-magazine" id='detalhes'>
                    <p className="f-reg">
                    <b className="title-apresentation display-6 mb-3 text-success">Estudante Internacional</b><br />
                    <br />
                    <b>Os candidatos internacionais</b> que pretendam frequentar um curso conferente de grau integrado na nossa instituição têm a possibilidade de o fazer através do concurso especial para estudantes internacionais, desde que cumpram as condições de acesso e ingresso específicas da instituição a que se candidatam. <br /><br />
                    Para conhecer as condições especiais destinadas aos candidatos internacionais aos cursos do Ispa – Instituto Superior Politécnico Atlântida consulte dos cursos internacionais  , consoante o nível de ensino em que tem interesse.
                    <br /><br />
                    </p>
                    <br />
                </div>
                <br /><br />
                </div>
                <div className="col-12 col-md-4 col-lg-3 col-xl-2 col-xxl-2 menu-color">
                <a href="/estudante-internacional" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-success mt-2 f-reg d-flex justify-content-between">
                    <span className="f-reg ">
                    Estudante internacional
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>
                <a href="/cursos" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark mt-2 f-reg d-flex justify-content-between">
                    <span className="f-reg ">
                    Licenciatura
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>
                <a href=" #" style={{textDecoration: 'none', borderBottom: '1px solid green'}} className="py-2 text-dark f-reg d-flex w-100 justify-content-between">
                    <span className="f-reg">
                   Mestrado
                    </span>
                    <i className="bi text-success bi-arrow-right" />
                </a>         
                </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            </main>
        <AppLayout />
        </>
    );
}

export default estudante;